<template lang="pug">
div.specification-popup
  h2.specification-popup-title {{ $t('stepGeneral.specList') }}
  CloseCircle(@click="$emit('closeSpecifications')")
  CustomScroll.specification-popup-content
      ul.specification-popup-content-col(v-for="(specCol, key) in specs" :key="key")
        li.specification-popup-content-item(v-for="(spec, key) in specCol" :key="key") {{ spec }}
</template>

<script>
import CustomScroll from "@/components/helps/CustomScroll";

export default {
  name: "SpecificationPopup",
  props: {
    specs: []
  },
  components: {
     CloseCircle: () => import('@/components/steps-faceless/CloseCircle'),
    CustomScroll
  },
}
</script>

<style lang="sass">
  .specification-popup-title
    font-style: normal
    font-weight: normal
    font-size: 30px
    line-height: 36px
    align-items: center
    text-align: center
    letter-spacing: 0.07em
    text-transform: uppercase
    color: rgba(0, 0, 0, 0.8)
    margin-bottom: 36px
  .specification-popup
    border: 1px solid rgba(58, 57, 57, 0.4)
    position: absolute
    width: calc(100% - 30px)
    height: calc(100% - 30px)
    background: rgba(255, 255, 255, 0.85)
    top: 15px
    left: 15px
    backdrop-filter: blur(15px)
    padding: 25px
    z-index: 9
  .specification-popup-content-item
    font-size: 14px
    color: rgba(58, 57, 57, 0.8)
    line-height: 20px
    max-width: 360px
    margin-bottom: 10px
  .specification-popup-content
    width: 90%
    max-width: 1320px
    margin: auto
    height: 575px
    .custom-scrollbar__inner
      display: flex
</style>